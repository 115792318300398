





























































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import FleetApplicationsModule from '@/store/modules/fleet/applications'
import DriverApplicationsModule from '@/store/modules/driver/applications'

// INTERFACES
import { ApplicationTabName, DictionaryRequestResource, IApplicationFilterForm } from '@/store/types'

// HELPERS
import { tableOptionsToFilters } from '@/utils/functions'

@Component({
  components: {
    DrawerFooter,
  },
})
export default class ApplicationsFilterForm extends Mixins(SystemMixin) {

  private form: IApplicationFilterForm = {
    vin: '',
    number: '',
    requestStartDate: '',
    requestEndDate: '',
    closeStartDate: '',
    closeEndDate: '',
    category: '',
  }

  private get activeFilters (): IApplicationFilterForm {
    if (this.role === 'fleet') {
      return tableOptionsToFilters(FleetApplicationsModule[`${this.tabName}Filters`])
    } else {
      return tableOptionsToFilters(FleetApplicationsModule.filters)
    }
  }

  // NOTE: Эта переменная используется для выбора источника данных
  private get tabName (): ApplicationTabName {
    return this.$route.params.tab as ApplicationTabName
  }

  // NOTE: Эта переменная используется для отправки в стор
  private get payload (): { entity: number, tabName: ApplicationTabName } {
    return { entity: +this.entity, tabName: this.tabName }
  }

  private get activeFiltersCount (){
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof IApplicationFilterForm] && item !== 'endDate') {
        count++
      }
    }
    return count
  }

  private get filtersDictionary (): DictionaryRequestResource {
    if (this.role === 'fleet') {
      return FleetApplicationsModule[`${this.tabName}Dictionaries`]
    } else {
      return DriverApplicationsModule.filtersDictionaries
    }
  }

  private get requestPeriod (): any {
    const period = []
    if (this.form.requestStartDate && this.form.requestEndDate){
      period.push(this.form.requestStartDate)
      period.push(this.form.requestEndDate)
    }
    return period
  }

  private set requestPeriod (value: any) {
    this.form.requestStartDate = value[0]
    this.form.requestEndDate = value[1]
  }

  private get closePeriod (): any {
    const period = []
    if (this.form.closeStartDate && this.form.closeEndDate){
      period.push(this.form.closeStartDate)
      period.push(this.form.closeEndDate)
    }
    return period
  }

  private set closePeriod (value: any) {
    this.form.closeStartDate = value[0]
    this.form.closeEndDate = value[1]
  }

  private created () {
    if (this.role === 'fleet') {
      FleetApplicationsModule.getDictionaries(this.payload)
    } else {
      DriverApplicationsModule.getDictionaries(this.payload)
    }

    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }

  private clearFilters () {
    this.form = {
      vin: '',
      number: '',
      requestStartDate: '',
      requestEndDate: '',
      closeStartDate: '',
      closeEndDate: '',
      category: '',
    }

    this.applyFilters(false)
  }

  private applyFilters (close = true) {
    if (this.role === 'fleet') {
      FleetApplicationsModule.setTableFilters({ tabName: this.tabName, data: this.form })
      FleetApplicationsModule.getTableData(this.payload)
      FleetApplicationsModule.getTableStatuses(this.payload)
    } else {
      DriverApplicationsModule.setTableFilters(this.form)
      DriverApplicationsModule.getTableData(+this.entity)
      DriverApplicationsModule.getTableStatuses(+this.entity)
    }

    if (close) {
      this.$closeDrawer()
    }
  }
}
